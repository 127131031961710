import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import YouTube from "react-youtube"
import { Box } from "@mui/material"
import Back from "../images/Back.svg"
import Skip from "../images/Skip.svg"
import Play from "../images/Play.svg"
import Pause from "../images/Pause.svg"
import Triangle from "../images/Triangle.svg"
import { Seo } from "./seo"
let player = null

const TVPage = ({ pageContext }) => {
  const [page, setPage] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const ref = useRef()
  const { videos } = pageContext
  const onReady = (e) => {
    player = e
  }
  const play = () => {
    if (player) player.target.playVideo()
  }

  const pause = () => {
    if (player) player.target.pauseVideo()
  }
  const next = () => {
    if (page + 1 === videos.length) setPage(0)
    else setPage((page) => page + 1)
  }
  const back = () => {
    if (page === 0) setPage(videos.length - 1)
    else setPage((page) => page - 1)
  }
  useEffect(() => {
    if (ref.current?.complete) {
      setLoaded(true)
    }
  }, [])
  return (
    <div className="video-page">
      <div
        style={{
          position: "relative",
          maxHeight: "100%",
          overflow: "hidden",
        }}
      >
        <img
          alt=""
          ref={ref}
          className="opacity-1"
          src={
            "https://cdn.sanity.io/images/n5gyqzp2/production/dfbfd0421431e4072ca1c48570fb69a6c15c5450-1920x1080.png"
          }
          onLoad={() => setLoaded(true)}
          style={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            maxHeight: "100vh",
          }}
        />

        <Seo title="Para*TV" />

        <div
          style={{
            position: "absolute",
            top: "6%",
            left: "5%",
            zIndex: 1,
            height: "88%",
            width: "78%",
          }}
        >
          {loaded ? (
            <YouTube
              className="youtube-container"
              videoId={videos[page]}
              opts={{
                height: "100%",
                width: "100%",
                playerVars: {
                  autoplay: loaded ? 1 : 0,
                  playsinline: 1,
                  iv_load_policy: 3,
                  rel: 0,
                  mute: 0,
                  controls: 0,
                  modestbranding: 1,
                },
              }}
              onReady={onReady}
            />
          ) : null}
        </div>
        <Box display={loaded ? "block" : "none"}>
          <Link to={`/shop`}>
            <Triangle className="triangle-button tv-button clickable" />
          </Link>
          <Play className="play-button tv-button clickable" onClick={play} />
          <Pause className="pause-button tv-button clickable" onClick={pause} />
          <Back className="back-button tv-button clickable" onClick={back} />
          <Skip className="skip-button tv-button clickable" onClick={next} />
        </Box>
      </div>
    </div>
  )
}
export default TVPage
